import React from "react";

export const onSuccessRedirect = (token, type) => {
  const successPathname = `/bankidcomplete?autostarttoken=${token}&type=${type}`;
  parent.postMessage({ successPathname }, "*");
  // window.location.replace(successLink);
};

export const errorOccurred = message => {
  return !(
    !message ||
    message === "Ok" ||
    message === "outstandingTransaction" ||
    message === "noClient" ||
    message === "userSign" ||
    message === "started"
  );
};

export const transcriptCollectStatus = (errorKey, t, method) => {
  switch (errorKey) {
    case "noClient":
      return t("t:user-messages.start-your-app");
    case "cancelled":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.cancelled")}</div>
        </>
      );
    case "alreadyInProgress":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.already-in-progress")}</div>
        </>
      );
    case "requestTimeout":
    case "maintenance":
    case "internalError":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.internal-error")}</div>
        </>
      );
    case "userCancel":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.user-cancel")}</div>
        </>
      );
    case "expiredTransaction":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.expired-transaction")}</div>
        </>
      );
    case "userSign":
      return t("t:user-messages.user-sign");
    case "outstandingTransaction":
      return t("t:user-messages.outstanding-transaction");
    case "started":
      return t("t:user-messages.started");
    case "certificateErr":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.certificate-err")}</div>
        </>
      );
    case "startFailed":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">
            {method === "bankIDWithQRCode" ? t("t:user-messages.start-failed-qr") : t("t:user-messages.start-failed")}
          </div>
        </>
      );
    case "unknownError":
      return (
        <>
          <div className="error-icon mr-11" />
          <div className="text-wrapper">{t("t:user-messages.unknown-error")}</div>
        </>
      );
    default:
      return t("t:common.starting-identification-process");
  }
};

export const iOS = () => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};
