import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Grow from "@material-ui/core/Grow";

import LogoWrapper from "../../Shared/LogoWrapper";
import DefaultButton from "../../Shared/DefaultButton/DefaultButton";
import urls from "../../core/urls";
import { getCancelRequest, getCollectRequest, postAuthAuthostart, resetStore } from "../../core/actions";
import { errorOccurred, iOS, onSuccessRedirect, transcriptCollectStatus } from "../../core/helpers";

import "./styles.scss";

const BankIDOnThisDevice = () => {
  const [collectInterval, setCollectInterval] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { IP, type, autoStartToken, buttonLoading, collectErrorCode, collectStatus } = useSelector(({ app }) => app);

  const fetchAutostartToken = () => {
    dispatch(postAuthAuthostart({ ip_address: IP }));
  };

  useEffect(() => {
    fetchAutostartToken();
    return () => {
      dispatch(resetStore());
      if (collectInterval) clearInterval(collectInterval);
    };
  }, []);

  const handleCollectInterval = () => {
    setCollectInterval(
      setInterval(() => {
        dispatch(getCollectRequest(autoStartToken));
      }, 2000)
    );
  };

  const onStartManuallyClick = () => {
    const iOSUrl = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
    const startManuallyUrl = iOS() ? iOSUrl : url;
    parent.postMessage({ startManuallyUrl }, "*");
    // window.open(iOS() ? iOSUrl : url, "_blank");
  };

  useEffect(() => {
    if (autoStartToken) {
      onStartManuallyClick();
      if (collectInterval) clearInterval(collectInterval);
      handleCollectInterval();
    }
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === "complete") {
      if (collectInterval) clearInterval(collectInterval);
      if (collectStatus?.toLowerCase() === "complete") onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus]);

  const onCancelClick = () => {
    dispatch(getCancelRequest(autoStartToken));
    dispatch(resetStore());
    if (collectInterval) clearInterval(collectInterval);
    history.push(urls.chooseMethod.path);
  };

  return (
    <Grow in>
      <div className="bank-id-on-this-device">
        <LogoWrapper text={t("t:common.bankid-on-this-device")} />
        {errorOccurred(collectErrorCode) ? (
          <section className="bank-id-on-this-device__wrapper">
            <div className="bank-id-on-this-device__status-text">
              {transcriptCollectStatus(collectErrorCode, t, "bankIDOnAnotherDevice")}
            </div>
            <div className="bank-id-on-this-device__buttons">
              <button type="button" onClick={onCancelClick} className="cancel-btn">
                {t("t:common.cancel")}
              </button>
            </div>
          </section>
        ) : (
          <section className="bank-id-on-this-device__wrapper">
            <p className="bank-id-on-this-device__text">{t("t:common.bankid-will-be-launched-in-few-seconds")}</p>
            <p className="bank-id-on-this-device__text">{t("t:common.if-app-does-not-start-press")}</p>
            <div className="bank-id-on-this-device__buttons">
              <DefaultButton
                variant="contained"
                formAction
                loading={buttonLoading}
                onClick={onStartManuallyClick}
                disabled={buttonLoading}
              >
                {t("t:common.start-manually")}
              </DefaultButton>
              <button onClick={onCancelClick} className="cancel-btn">
                {t("t:common.cancel")}
              </button>
            </div>
          </section>
        )}
      </div>
    </Grow>
  );
};

export default BankIDOnThisDevice;
