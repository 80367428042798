import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Grow from "@material-ui/core/Grow";

import LogoWrapper from "../../Shared/LogoWrapper";
import urls from "../../core/urls";
import {
  getAnimatedQRRequest,
  getCancelRequest,
  getCollectRequest,
  postAuthAuthostart,
  resetStore
} from "../../core/actions";
import { errorOccurred, onSuccessRedirect, transcriptCollectStatus } from "../../core/helpers";

import "./styles.scss";
import SkeletonComponent from "../../Shared/Skeleton/Skeleton";

const BankIDWithQRCode = () => {
  const [qrIntervalId, setQrIntervalId] = useState(null);
  const [collectIntervalId, setCollectIntervalId] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { IP, type, autoStartToken, qrCode, collectErrorCode, collectStatus } = useSelector(({ app }) => app);

  const onUnmount = () => {
    dispatch(resetStore());
    if (qrIntervalId) clearInterval(qrIntervalId);
    if (collectIntervalId) clearInterval(collectIntervalId);
  };

  const fetchAutostartToken = () => {
    dispatch(postAuthAuthostart({ ip_address: IP }));
  };

  useEffect(() => {
    fetchAutostartToken();
    return () => onUnmount();
  }, []);

  const handleQRInterval = async () => {
    if (autoStartToken) {
      await dispatch(getAnimatedQRRequest(autoStartToken));
    }
  };

  const handleCollectInterval = async () => {
    if (autoStartToken) {
      dispatch(getCollectRequest(autoStartToken));
    }
  };

  useEffect(() => {
    if (autoStartToken) {
      handleQRInterval();

      // Set the interval for QR code requests every 3 seconds
      const qrId = setInterval(handleQRInterval, 3000);
      setQrIntervalId(qrId);

      // Delay the first collect request by 4 seconds
      const collectTimeoutId = setTimeout(() => {
        handleCollectInterval(); // First call after 4 seconds
        const collectId = setInterval(handleCollectInterval, 4000); // Subsequent calls every 4 seconds
        setCollectIntervalId(collectId);
      }, 4000);

      return () => {
        clearInterval(qrId);
        clearTimeout(collectTimeoutId);
        if (collectIntervalId) clearInterval(collectIntervalId);
      };
    }
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === "complete") {
      if (qrIntervalId) clearInterval(qrIntervalId);
      if (collectIntervalId) clearInterval(collectIntervalId);
      if (collectStatus?.toLowerCase() === "complete") onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus, qrIntervalId, collectIntervalId]);

  const onCancelClick = async () => {
    dispatch(getCancelRequest(autoStartToken));
    onUnmount();
    history.push(urls.chooseMethod.path);
  };

  return (
    <Grow in>
      <div className="bank-id-with-qr-code">
        <LogoWrapper text={t("t:common.bankid-with-qr-code")} />
        {errorOccurred(collectErrorCode) ? (
          <section className="bank-id-with-qr-code__wrapper">
            <div className="bank-id-with-qr-code__status-text">
              {transcriptCollectStatus(collectErrorCode, t, "bankIDOnAnotherDevice")}
            </div>
            <div className="bank-id-with-qr-code__buttons">
              <button type="button" onClick={onCancelClick} className="cancel-btn">
                {t("t:common.cancel")}
              </button>
            </div>
          </section>
        ) : (
          <section className="bank-id-with-qr-code__wrapper">
            <p className="bank-id-with-qr-code__text">{t("t:common.launch-bankid-app-and-read-qr-code")}</p>
            <SkeletonComponent loading={!qrCode} variant="rectangle" width={128} height={128} classes="qr-code">
              <img src={`data:image/png;base64,${qrCode}`} alt="qr code" className="bank-id-with-qr-code__qr-code" />
            </SkeletonComponent>
            <button onClick={onCancelClick} className="cancel-btn">
              {t("t:common.cancel")}
            </button>
          </section>
        )}
      </div>
    </Grow>
  );
};

export default BankIDWithQRCode;
