import {
  POST_AUTH,
  POST_AUTH_AUTHOSTART,
  GET_ANIMATED_QR,
  GET_CANCEL,
  GET_COLLECT,
  SET_USER_IP,
  SET_USER_TYPE,
  RESET_STORE,
  RESET_COLLECT_STATUS
} from "./actionTypes";

export function setUserIP(IP) {
  return {
    type: SET_USER_IP,
    IP
  };
}

export function setUserType(urlType) {
  return {
    type: SET_USER_TYPE,
    urlType
  };
}

export function resetStore() {
  return {
    type: RESET_STORE
  };
}

export function resetCollectStatus() {
  return {
    type: RESET_COLLECT_STATUS
  };
}

export function postAuth(data) {
  return {
    type: POST_AUTH,
    payload: {
      client: "default",
      request: {
        url: "/Auth",
        method: "post",
        data
        // {
        //   "SSN": "860805-0101",
        //   "IP": "192.168.0.1"
        // }
      }
    }
  };
}

export function postAuthAuthostart(data) {
  return {
    type: POST_AUTH_AUTHOSTART,
    payload: {
      client: "default",
      request: {
        url: "/AuthAutostart",
        method: "post",
        data
        // {
        //   "IP": "192.168.0.1"
        // }
      }
    }
  };
}

export function getAnimatedQRRequest(autoStartToken) {
  return {
    type: GET_ANIMATED_QR,
    payload: {
      client: "default",
      request: {
        url: `/animatedqr/${autoStartToken}`,
        method: "get"
      }
    }
  };
}

export function getCancelRequest(autoStartToken) {
  return {
    type: GET_CANCEL,
    payload: {
      client: "default",
      request: {
        url: `/Cancel/${autoStartToken}`,
        method: "get"
      }
    }
  };
}

export function getCollectRequest(autoStartToken) {
  return {
    type: GET_COLLECT,
    payload: {
      client: "default",
      request: {
        url: `/Collect/${autoStartToken}`,
        method: "get"
      }
    }
  };
}
